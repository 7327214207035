import React, { Component } from "react"
import "../styles/global.css"
import Bio from './bio.js'

class Homepage extends Component {
  render() {
    return <Bio></Bio>
  }
}

export default Homepage
